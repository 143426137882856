import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { verifyEmail } from "../../services/authService";

export interface VerifyEmailProps {}

type verifyParams = {
  id: string;
  token: string;
};

const VerifyEmail: React.FC<VerifyEmailProps> = () => {
  const history = useHistory();
  const { id, token } = useParams<verifyParams>();

  const [message, setMessage] = useState(
    "Please wait while we validate your email"
  );

  useEffect(() => {
    const doVerifyEmail = async () => {
      const response = await verifyEmail(id, token);

      if (response.status === 200) {
        return history.push("/");
      } else if (response.status === 400) {
        return setMessage(response.data);
      }
    };

    doVerifyEmail();
  });

  return (
    <section className="columns mt-6 profile has-text-centered">
      <div className="column is-6 is-offset-3">
        <p>{message}</p>
      </div>
    </section>
  );
};

export default VerifyEmail;
