import { ReleaseInterface } from '../../../interfaces/collection';
import './SearchResult.scss';

export interface SearchResultProps {
  release: ReleaseInterface;
  onShowInfo: (instance_id: string) => void;
}

const SearchResult: React.FC<SearchResultProps> = ({ release, onShowInfo }) => {
  return (
    <li onClick={() => onShowInfo(release.instance_id)}>
      <h5>
        <strong>{release.artists.map((artist) => artist.name).join(', ')}</strong> - {release.title}
      </h5>
      <img src={release.cover_image} alt={release.title} className='search-result-cover' />
    </li>
  );
};

export default SearchResult;
