import ShelfSizeField from "../ShelfSetupFormComp/ShelfSizeField/ShelfSizeField";
import ReleasesPerShelfField from "../ShelfSetupFormComp/ReleasesPerShelfField/ReleasesPerShelfField";
import SortByField from "../ShelfSetupFormComp/SortByField/SortByField";
import SelectFolderField from "../ShelfSetupFormComp/SelectFolderField/SelectFolderField";
import { FolderInterface } from "../../interfaces/collection";
import "./ShelfSetupForm.scss";

export interface ShelfSetupFormProps {
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  handleSubmit: (e: React.SyntheticEvent) => void;
  width: number;
  height: number;
  releasesPerShelf: number;
  sortBy: string[];
  addDimension: () => void;
  removeDimension: () => void;
  folders: FolderInterface[];
  selectedFolderId: string;
}

const ShelfSetupForm: React.FC<ShelfSetupFormProps> = ({
  handleChange,
  handleSubmit,
  width,
  height,
  releasesPerShelf,
  sortBy,
  addDimension,
  removeDimension,
  folders,
  selectedFolderId,
}) => {
  return (
    <form id="shelf-setup-form">
      <SelectFolderField
        folders={folders}
        selectedFolderId={selectedFolderId}
        handleChange={handleChange}
      />
      <ShelfSizeField
        width={width}
        height={height}
        handleChange={handleChange}
      />
      <ReleasesPerShelfField
        releasesPerShelf={releasesPerShelf}
        handleChange={handleChange}
      />
      <SortByField
        sortBy={sortBy}
        addDimension={addDimension}
        removeDimension={removeDimension}
        handleChange={handleChange}
        selectedFolderId={selectedFolderId}
      />
      <button onClick={handleSubmit} type="submit" className="btn">
        Submit
      </button>
    </form>
  );
};

export default ShelfSetupForm;
