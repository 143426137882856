import './Loader.scss';
import { randomStr8Fact } from '../../services/musicFacts';
import { CgDisc } from 'react-icons/cg';

export interface LoaderProps {}

const Loader: React.FC<LoaderProps> = () => {
  return (
    <div className='loader-wrapper'>
      <h2>{randomStr8Fact()}</h2>
      <CgDisc className='spins loader' />
    </div>
  );
};

export default Loader;
