import { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { register } from "../../services/authService";
import "./Register.scss";
import Joi from "joi";

export interface RegisterProps {
  // onAuthenticate: (username: string) => void;
}

const Register: React.FC<RegisterProps> = () => {
  const history = useHistory();
  const [resendLink, setResendLink] = useState(false);
  const [errors, setErrors] = useState<
    { message: string; target: React.ReactText | null }[]
  >([]);
  const [formState, setFormState] = useState({
    email: "",
    password: "",
    password2: "",
  });

  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .min(6)
      .max(255)
      .required()
      .label("Email"),
    password: Joi.string().min(6).max(1024).required().label("Password"),
    password2: Joi.any()
      .equal(Joi.ref("password"))
      .required()
      .label("Confirm password")
      .messages({ "any.only": "{{#label}} does not match" }),
  });

  const validate = () => {
    const { error } = schema.validate(formState, { abortEarly: false });
    if (!error) return null;
    const newErrors = error.details.map((x) => {
      return { message: x.message, target: x.path[0] };
    });
    return newErrors;
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setErrors([]);
    setResendLink(false);
    const newErrors = validate();
    if (newErrors) {
      setErrors(newErrors);
    } else {
      const { password, email } = formState;
      const response = await register(email, password);
      if (response.status === 200) {
        if (response)
          return history.push("/please-verify-your-account/" + email);
      } else if (response.data) {
        if (response.data.includes("verified")) {
          setResendLink(true);
          setErrors([{ message: response.data, target: null }]);
        }
        setErrors([{ message: response.data, target: null }]);
      } else {
        setErrors([
          {
            message:
              "An unknown error has occured, I'm as disapointed as you are.",
            target: null,
          },
        ]);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="login-form">
      <label htmlFor="email">Email</label>
      <input
        type="text"
        name="email"
        onChange={(e) => setFormState({ ...formState, email: e.target.value })}
      />
      {errors.find((err) => err.target === "email") && (
        <p className="is-error">
          {errors.find((err) => err.target === "email")?.message}
        </p>
      )}
      <label htmlFor="password">Password</label>
      <input
        type="password"
        name="password"
        onChange={(e) =>
          setFormState({ ...formState, password: e.target.value })
        }
      />
      {errors.find((err) => err.target === "password") && (
        <p className="is-error">
          {errors.find((err) => err.target === "password")?.message}
        </p>
      )}
      <label htmlFor="password2">Confirm password</label>
      <input
        type="password"
        name="password2"
        onChange={(e) =>
          setFormState({ ...formState, password2: e.target.value })
        }
      />
      {errors.find((err) => err.target === "password2") && (
        <p className="is-error">
          {errors.find((err) => err.target === "password2")?.message}
        </p>
      )}
      {errors.find((err) => err.target === null) && (
        <p className="is-error">
          {errors.find((err) => err.target === null)?.message}
        </p>
      )}
      {resendLink && (
        <p>
          <Link to="/resend-activation-link" className="is-blue">
            Do you want to resend the activation link?
          </Link>
        </p>
      )}
      <button className="btn">Register</button>
      <p>
        Already have an account? <br />{" "}
        <Link to="/" className="is-blue">
          Login here.
        </Link>
      </p>
    </form>
  );
};

export default Register;
