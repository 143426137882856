import { ReleaseInterface } from '../../../interfaces/collection';
import Release from '../Release/Release';

export interface ShelfProps {
  releases: ReleaseInterface[];
  isLastPopulatedShelf: boolean;
  focusedRelease: (release: ReleaseInterface) => void;
  releaseWidth: number;
}

const Shelf: React.FC<ShelfProps> = ({ releases, isLastPopulatedShelf, focusedRelease, releaseWidth }) => {
  const raiseShowInfo = (releaseId: string) => {
    const release = releases.filter((release) => release.instance_id === releaseId)[0];

    focusedRelease({
      ...release,
    });
  };

  return (
    <td className={isLastPopulatedShelf ? 'last-populated-shelf' : ''}>
      {releases.map((release) => (
        <Release key={release.instance_id} id={release.instance_id} onShowInfo={raiseShowInfo} releaseWidth={releaseWidth}></Release>
      ))}
    </td>
  );
};

export default Shelf;
