import { VscInfo } from "react-icons/vsc";
import { FolderInterface } from "../../../interfaces/collection";
import Tooltip from "../../Tooltip/Tooltip";
import "./SelectFolderField.scss";

export interface SelectFolderFieldProps {
  folders: FolderInterface[];
  selectedFolderId: string;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}

const SelectFolderField: React.FC<SelectFolderFieldProps> = ({
  folders,
  selectedFolderId,
  handleChange,
}) => {
  return (
    <fieldset>
      <label>
        <span>Folder:</span>
        <select
          name="selectedFolderId"
          defaultValue={selectedFolderId || "0"}
          onChange={handleChange}
        >
          {folders.map((folder) => (
            <option value={folder.id} key={folder.id}>
              {folder.name}
            </option>
          ))}
        </select>
        <Tooltip text="These are the folders you created on Discogs.">
          <VscInfo></VscInfo>
        </Tooltip>
      </label>
    </fieldset>
  );
};

export default SelectFolderField;
