import { ReleaseInterface } from '../../interfaces/collection';
import SearchResult from '../SearchBarComp/SearchResult/SearchResult';
import './SearchBar.scss';

export interface SearchBarProps {
  value: string;
  onChange: (value: string) => void;
  searchResults: ReleaseInterface[];
  collection: ReleaseInterface[];
  focusedRelease: (release: ReleaseInterface) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ value, onChange, searchResults, collection, focusedRelease }) => {
  const raiseShowInfo = (releaseId: string) => {
    const release = collection.filter((release) => release.instance_id === releaseId)[0];

    focusedRelease({
      ...release,
    });
  };

  return (
    <div className='search-wrapper'>
      <input
        type='text'
        placeholder='Where is this record?'
        className='searchbar'
        name='query'
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
      <ul className='search-results'>
        {searchResults.map((release) => (
          <SearchResult release={release} key={release.instance_id} onShowInfo={raiseShowInfo} />
        ))}
      </ul>
    </div>
  );
};

export default SearchBar;
