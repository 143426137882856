import { VscArrowSmallLeft } from 'react-icons/vsc';
import './BackToFormButton.scss';

export interface BackToFormButtonProps {
  handleBackToForm: () => void;
}

const BackToFormButton: React.FC<BackToFormButtonProps> = ({ handleBackToForm }) => {
  return (
    <button onClick={handleBackToForm} className='icon-btn btn is-large mt-1'>
      <VscArrowSmallLeft />
    </button>
  );
};

export default BackToFormButton;
