import { VscChevronRight } from "react-icons/vsc";
import "./SelectDimensionField.scss";

export interface SelectDimensionFieldProps {
  val: string;
  isNotLast: boolean;
  selectedFolderId: string;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}

const SelectDimensionField: React.FC<SelectDimensionFieldProps> = ({
  isNotLast,
  selectedFolderId,
  val,
  handleChange,
}) => {
  const options = [
    {
      name: "Artist",
      value: "artists",
    },
    {
      name: "Label",
      value: "labels",
    },
    {
      name: "Year",
      value: "year",
    },
    {
      name: "Title",
      value: "title",
    },
    {
      name: "Genre",
      value: "genres",
    },
    {
      name: "Style",
      value: "styles",
    },
    ...(selectedFolderId === "0"
      ? [
          {
            name: "Folder",
            value: "folders",
          },
        ]
      : []),
  ];

  console.log(selectedFolderId);
  return (
    <>
      <select
        name="sortByDimension"
        defaultValue={val || ""}
        className="sort-by-dimension"
        onChange={handleChange}
      >
        <option value="" hidden>
          Select an option
        </option>
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.name}
          </option>
        ))}
      </select>
      {isNotLast && <VscChevronRight />}
    </>
  );
};

export default SelectDimensionField;
