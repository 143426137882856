import "./FAQ.scss";

export interface FAQProps {}

const FAQ: React.FC<FAQProps> = () => {
  return (
    <section className="columns mt-6 faq">
      <div className="column is-8 is-offset-2">
        <div className="question mb-3">
          <h2>Why would I use this?</h2>
          <p className="mb-1">
            When creating the app I had several use cases in mind:
          </p>
          <ul>
            <li>
              You could be a DJ with a big collection, this allows you to easily
              find your records before going to a gig.
            </li>
            <li>
              On the flip side you could be <strong>coming back</strong> from a
              gig, and would like to put all the records you took out back in
              the right place.
            </li>
            <li>
              You could be a record store owner who'd like to sort out some
              shelves separately.
            </li>
            <li>
              You could just be a collector or an organization nerd, who likes
              things to be tidy, and could use a tool to help with this.
            </li>
          </ul>
        </div>
        <div className="question mb-3">
          <h2>I'm using Safari and can't seem to link my Discogs account.</h2>
          <p>
            Unfortunately, Safari's last version blocks all pop-ups and I
            haven't found a way to circumvent it as of now. A solution is to
            click on "blocked pop-up" up in the address bar, then it should
            indeed bring you to your Discogs account.
          </p>
        </div>
        <div className="question mb-3">
          <h2>My Discogs collection has been updated! What should I do?</h2>
          <p>Just go to your profile here and click "Sync Discogs Data".</p>
        </div>
        <div className="question mb-3">
          <h2>
            I'd like to sort by Folders as opposed to relying on Discogs'
            genres.
          </h2>
          <p>
            This is possible! Just select "All" in the "Folders" dropdown,
            you'll then have "Folders" available as a dimension in the "Sort By"
            field.
          </p>
        </div>
        <div className="question mb-3">
          <h2>I'd like to have "Country" available as a sorting filter.</h2>
          <p>
            This is not available with the current version of the Discogs API in
            the way I use it. It hopefully will be in the future.
          </p>
        </div>
        <div className="question mb-3">
          <h2>I have several separated shelves for different collections.</h2>
          <p>
            That's where folders come in handy! One could have one folder
            "Japanese Jazz" for example, dedicated to a smaller shelf that will
            only host Japanese Jazz. If another shelf is dedicated to
            Scandinavian Breakcore, one could make another folder for this
            shelf, and simply launch two instances of the app, one for each
            folder/shelf.
          </p>
        </div>
        <div className="question mb-3">
          <h2>I have a really large collection/shelf.</h2>
          <p>
            Unfortunaly I still haven't found the most elegant way to display
            larger shelves (5x5++). Bear in mind this is a Beta version! This
            will get improved support as soon as I get time to.
          </p>
        </div>
        <div className="question mb-3">
          <h2>Are you affiliated with either Discogs or Ikea?</h2>
          <p>No I am not.</p>
        </div>
        <div className="question mb-3">
          <h2>I have a feature idea!</h2>
          <p>
            So do I! A lot of them even, they will be released in time. But
            you're welcome to email yours at{" "}
            <a href="mailto:hi@k4ll4x-organizer.com">hi@k4ll4x-organizer.com</a>
            .
          </p>
        </div>
        <div className="question mb-3">
          <h2>I want to help financially</h2>
          <p>
            Well first of all thanks, that's really nice of you. Any help to pay
            for the servers is welcome, my paypal is paypal.me/LucasSESBOUE
          </p>
        </div>
        <div className="question mb-3">
          <h2>I want to contact you</h2>
          <p>
            You're welcome to message me at{" "}
            <a href="mailto:hi@k4ll4x-organizer.com">hi@k4ll4x-organizer.com</a>
            .
          </p>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
