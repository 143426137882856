import { useEffect } from "react";
import {
  VscArrowSmallLeft,
  VscArrowSmallRight,
  VscClose,
} from "react-icons/vsc";
import _ from "lodash";
import { FolderInterface, ReleaseInterface } from "../../interfaces/collection";
import "./ReleaseCard.scss";

export interface ReleaseCardProps {
  release: ReleaseInterface | null;
  nextRelease: () => void;
  prevRelease: () => void;
  closeRelease: () => void;
  folders: FolderInterface[];
}

const ReleaseCard: React.FC<ReleaseCardProps> = ({
  release,
  nextRelease,
  prevRelease,
  closeRelease,
  folders,
}) => {
  useEffect(() => {
    const arrowNavigation = (e: KeyboardEvent): void => {
      if (release && e.keyCode === 37) {
        prevRelease();
      }
      if (release && e.keyCode === 39) {
        nextRelease();
      }
    };

    window.addEventListener("keydown", arrowNavigation, false);

    return () => {
      window.removeEventListener("keydown", arrowNavigation, false);
    };
  }, [release, nextRelease, prevRelease]);

  return release ? (
    <div className="release-card">
      <div className="release-card-controls">
        <div className="rl-controls">
          <button onClick={prevRelease} className="btn icon-btn is-dark">
            <VscArrowSmallLeft />
          </button>
          <button onClick={nextRelease} className="btn icon-btn is-dark">
            <VscArrowSmallRight />
          </button>
        </div>
        <button onClick={closeRelease} className="btn icon-btn is-dark">
          <VscClose />
        </button>
      </div>
      <div className="release-info">
        <figure className="release-cover mb-2">
          <img src={release.cover_image} alt={release.title} />
        </figure>
        <div>
          <p>
            <strong>Artist:</strong>{" "}
            {release.artists.map((artist) => artist.name).join(", ")}
          </p>
          <p>
            <strong>Title:</strong> {release.title}
          </p>
          <p>
            <strong>Label:</strong>{" "}
            {release.labels.map((label) => label.name).join(", ")}
          </p>
          <p>
            <strong>Year:</strong> {release.year}
          </p>
          {/* <p>
            <strong>Country:</strong> {release.country}
          </p> */}
          <p>
            <strong>Genre:</strong> {release.genres.join(", ")}
          </p>
          {!_.isEmpty(release.styles) && (
            <p>
              <strong>Style:</strong> {release.styles.join(", ")}
            </p>
          )}
          {release.folder_id !== "1" && (
            <p>
              <strong>Folder: </strong>
              {
                folders.find(
                  (folder) => folder.id.toString() === release.folder_id
                )?.name
              }
            </p>
          )}
        </div>
      </div>
      <br />
      <small>
        <p>
          <strong>Position:</strong> Row {release.position[0]}, Column{" "}
          {release.position[1]}, Position {release.position[2]}
        </p>
        <p>
          <strong>Previous on the shelf:</strong>{" "}
          {release.previousRelease
            ? release.previousRelease.artists[0].name +
              " - " +
              release.previousRelease.title
            : "This is the first record of the collection"}
        </p>
        <p>
          <strong>Next on the shelf:</strong>{" "}
          {release.nextRelease
            ? release.nextRelease.artists[0].name +
              " - " +
              release.nextRelease.title
            : "This is the last record of the collection"}
        </p>
      </small>
    </div>
  ) : (
    <div className="release-card hidden"></div>
  );
};

export default ReleaseCard;
