import './Release.scss';

export interface ReleaseProps {
  onShowInfo: (id: string) => void;
  id: string;
  releaseWidth: number;
}

const Release: React.FC<ReleaseProps> = ({ onShowInfo, id, releaseWidth }) => {
  return <span className='release' onClick={() => onShowInfo(id)} style={{ width: releaseWidth }}></span>;
};

export default Release;
