import { useState } from "react";
import { login } from "../../services/authService";
import { Link } from "react-router-dom";
import Joi from "joi";
import "./Login.scss";

export interface LoginProps {
  onAuthenticate: () => void;
}

const Login: React.FC<LoginProps> = ({ onAuthenticate }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resendLink, setResendLink] = useState(false);
  const [errors, setErrors] = useState<
    { message: string; target: React.ReactText | null }[]
  >([]);

  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .min(6)
      .max(255)
      .required()
      .label("Email"),
    password: Joi.string().required().label("Password"),
  });

  const validate = () => {
    const { error } = schema.validate(
      { email, password },
      { abortEarly: false }
    );
    if (!error) return null;
    const newErrors = error.details.map((x) => {
      return { message: x.message, target: x.path[0] };
    });
    return newErrors;
  };

  const handleSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault();
    setErrors([]);
    setResendLink(false);
    const newErrors = validate();
    if (newErrors) {
      setErrors(newErrors);
    } else {
      const response = await login(email, password);

      if (response.status === 200) {
        const { jwtoken } = response.data;
        localStorage.setItem("auth-token", jwtoken);
        onAuthenticate();
      } else if (response.data) {
        if (response.data.includes("verified")) {
          setResendLink(true);
          setErrors([{ message: response.data, target: null }]);
        } else {
          setErrors([{ message: response.data, target: null }]);
        }
      } else {
        setErrors([
          {
            message:
              "An unknown error has occured, I'm as disapointed as you are.",
            target: null,
          },
        ]);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="login-form">
      <label htmlFor="email">Email</label>
      <input
        type="text"
        name="email"
        onChange={(e) => setEmail(e.target.value)}
      />
      {errors.find((err) => err.target === "email") && (
        <p className="is-error">
          {errors.find((err) => err.target === "email")?.message}
        </p>
      )}
      <label htmlFor="password">Password</label>
      <input
        type="password"
        name="password"
        onChange={(e) => setPassword(e.target.value)}
      />
      {errors.find((err) => err.target === "password") && (
        <p className="is-error">
          {errors.find((err) => err.target === "password")?.message}
        </p>
      )}
      {errors.find((err) => err.target === null) && (
        <p className="is-error">
          {errors.find((err) => err.target === null)?.message}
        </p>
      )}
      {resendLink && (
        <p>
          <Link to="/resend-activation-link" className="is-blue">
            Do you want to resend the activation link?
          </Link>
        </p>
      )}
      <button className="btn">Login</button>
      <p>
        Don't have an account yet? <br />{" "}
        <Link to="/register" className="is-blue">
          Register here.
        </Link>
      </p>
    </form>
  );
};

export default Login;
