const str8Factzz: string[] = [
  "In 2016, Mozart Sold More CDs than Beyoncé",
  "Rod Stewart Hosted the Largest Ever Free Concert",
  "'Wanna Be' by The Spice Girls is the Catchiest Song of All Time",
  "The British Navy Uses Britney Spears Songs to Scare Off Pirates",
  "Barry Manilow Didn't Write 'I Write the Songs'",
  "None of The Beatles Could Read or Write Music",
  "Metallica is the First and Only Band to Have Played on All 7 Continents",
  "The Offspring's First Drummer Left the Band to Become a Gynecologist",
  "Prince Played 27 Instruments on His Debut Album",
  "Despacito by Luis Fonsi and Daddy Yankee have 6.6 billion views on YouTube as of February 2020.",
  "Eminem’s Rap God has the most words in a song, at 1,560 words.",
  "Eminem’s first album only sold 1000 copies.",
  "Japan has the shortest national anthem in the world.",
  "Music by Tina Turner is used at Gloucestershire UK airport to scare birds away from the runway.",
  "Freddie Mercury's famous overbite was the result of him having four extra teeth in his upper jaw.",
  "Freddie Mercury helped Princess Diana sneak into a bar by disguising her in an army jacket, a cap and sunglasses.",
  "Dolly Parton once entered a drag queen celebrity impersonator contest as herself and lost.",
  "Nicole Scherzinger sang 95% of the vocals while she was in the Pussycat Dolls.",
  "David Bowie's eyes weren't actually two different colours.",
  "The word “jazz” was originally (around 1912) US slang for excitement or energy.",
];

export function randomStr8Fact(): string {
  return str8Factzz[Math.floor(Math.random() * str8Factzz.length)];
}
