import { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  getFolderReleases,
  getFolders,
} from "../../services/collectionService";
import ShelfSetupForm from "../../components/ShelfSetupForm/ShelfSetupForm";
import ShelvingUnit from "../../components/ShelvingUnit/ShelvingUnit";
import ReleaseCard from "../../components/ReleaseCard/ReleaseCard";
import BackToFormButton from "../../components/BackToFormButton/BackToFormButton";
import SearchBar from "../../components/SearchBar/SearchBar";
import Loader from "../../components/Loader/Loader";
import "./Dashboard.scss";
import _, { isEmpty } from "lodash";
import { ReleaseInterface, FolderInterface } from "../../interfaces/collection";

const initFormState = {
  width: 4,
  height: 4,
  releasesPerShelf: 40,
  sortBy: ["artists"],
  selectedFolderId: "0",
};

interface FormStateInterface {
  width: number;
  height: number;
  releasesPerShelf: number;
  sortBy: string[];
  selectedFolderId: string;
}

export interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  const [releasesPerShelf, setReleasesPerShelf] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string[]>(["artists"]);
  const [focusedRelease, setFocusedRelease] =
    useState<ReleaseInterface | null>(null);
  const [collection, setCollection] = useState<ReleaseInterface[]>([]);
  const [formState, setFormState] = useState<FormStateInterface>(initFormState);
  const [formIsVisible, setFormIsVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<ReleaseInterface[]>([]);
  const [folders, setFolders] = useState<FolderInterface[]>([]);
  const [selectedFolderId, setSelectedFolderId] = useState<string>("");

  useEffect(() => {
    const fetchFolders = async () => {
      const folders = await getFolders();
      setFolders(folders);
    };

    fetchFolders();
  }, []);

  useEffect(() => {
    const newCollection = handleSorting(collection, sortBy);
    setCollection(newCollection);
  }, [sortBy, releasesPerShelf, width, height]);

  const isInitialMount = useRef(true);

  useEffect(() => {
    const fetchFolderReleases = async () => {
      setLoading(true);
      let newCollection = await getFolderReleases(selectedFolderId);

      // To be deleted/updated later, created to help making deepsorting, as the absence of style on some releases throws an error
      for (let release of newCollection) {
        _.isEmpty(release.styles) && release.styles.push("No style specified");
      }

      const sortedCollection = handleSorting(newCollection, sortBy);

      setCollection(sortedCollection);
      setLoading(false);
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      fetchFolderReleases();
    }
  }, [selectedFolderId]);

  const handleHighlighting = (release: ReleaseInterface | null) => {
    const releaseDomElements = document.getElementsByClassName("release");
    for (let i = 0; i < releaseDomElements.length; i++) {
      if (releaseDomElements[i].classList.contains("highlighted")) {
        releaseDomElements[i].classList.remove("highlighted");
      }
    }
    // clean this up later. Maybe I need to remove null from the type ?
    if (release && release.position && releasesPerShelf && width) {
      const { position } = release;
      const releaseIndex =
        (position[0] - 1) * releasesPerShelf * width +
        (position[1] - 1) * releasesPerShelf +
        position[2] -
        1;
      const highlightedRelease = releaseDomElements[releaseIndex];

      highlightedRelease.classList.add("highlighted");
    }
  };

  const handleFocusedRelease = (release: ReleaseInterface) => {
    handleHighlighting(release);
    setFocusedRelease(release);
    setSearchQuery("");
    setSearchResults([]);
  };

  const [debouncedQuery, setDebouncedQuery] = useState("");

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 300);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchQuery]);

  useEffect(() => {
    handleSearch(debouncedQuery);
  }, [debouncedQuery]);

  const handleSearch = (query: string) => {
    const checkArtistMatch = (release: ReleaseInterface, query: string) => {
      return release.artists.find((artist) =>
        artist.name.toLowerCase().includes(query.toLowerCase())
      );
    };

    const checkLabelMatch = (release: ReleaseInterface, query: string) => {
      return release.labels.find((label) =>
        label.name.toLowerCase().includes(query.toLowerCase())
      );
    };

    const checkTitleMatch = (release: ReleaseInterface, query: string) => {
      return release.title.toLowerCase().includes(query.toLowerCase());
    };

    const filtered = query
      ? collection.filter(
          (release) =>
            checkArtistMatch(release, query) ||
            checkLabelMatch(release, query) ||
            checkTitleMatch(release, query)
        )
      : [];

    setSearchResults(filtered);
  };

  const handleSorting = (collection: ReleaseInterface[], values: string[]) => {
    const sortedCollection = [...collection].sort((a, b): number => {
      const formatCompare = (value: string) => {
        if (value === "year") return a[value] - b[value];
        if (value === "labels" || value === "artists")
          return a[value][0].name.localeCompare(b[value][0].name);
        // No coutries for now
        // if (value === 'country') return a[value].localeCompare(b[value]);
        if (value === "title") return a[value].localeCompare(b[value]);
        if (value === "genres" || value === "styles")
          return a[value][0].localeCompare(b[value][0]);
        if (value === "folders") {
          const aFolderName = folders.find(
            (folder) => a.folder_id === folder.id.toString()
          )?.name;

          const bFolderName = folders.find(
            (folder) => b.folder_id === folder.id.toString()
          )?.name;

          return aFolderName && bFolderName
            ? aFolderName.localeCompare(bFolderName)
            : 1;
        }
      };

      return values.reduce<any>((acc, cur) => acc || formatCompare(cur), 0);
    });

    return addPositions(sortedCollection);
  };

  const handleAddDimension = () => {
    const newFormState = { ...formState };
    newFormState.sortBy.push("");
    setFormState(newFormState);
  };

  const handleRemoveDimension = () => {
    const newFormState = { ...formState };
    newFormState.sortBy.pop();
    setFormState(newFormState);
  };

  const addPositions = (collection: ReleaseInterface[]): ReleaseInterface[] => {
    if (width && releasesPerShelf) {
      const releasesPerRow = width * releasesPerShelf;

      const newCollection = collection.map((release, i) => {
        const row = Math.floor(i / releasesPerRow);
        const col = Math.floor((i - row * releasesPerRow) / releasesPerShelf);
        const position = i - row * releasesPerRow - col * releasesPerShelf;
        release.position = [row + 1, col + 1, position + 1];
        release.previousRelease = collection[i - 1]
          ? {
              artists: collection[i - 1].artists,
              title: collection[i - 1].title,
            }
          : null;
        release.nextRelease = collection[i + 1]
          ? {
              artists: collection[i + 1].artists,
              title: collection[i + 1].title,
            }
          : null;
        return release;
      });

      return newCollection;
    }
    return [];
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const newFormState: FormStateInterface = { ...formState };
    if (name === "sortByDimension") {
      const dimensions = Array.prototype.slice.call(
        document.querySelectorAll(".sort-by-dimension")
      );
      const i = dimensions.indexOf(e.target);
      const newSortBy: string[] = [...newFormState.sortBy];
      newSortBy[i] = value;
      newFormState.sortBy = newSortBy;
    } else if (
      name === "width" ||
      name === "height" ||
      name === "releasesPerShelf"
    ) {
      newFormState[name] = parseInt(value);
    } else if (name === "selectedFolderId") {
      newFormState[name] = value;
    }

    setFormState(newFormState);
  };

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!location.search) {
      setFormIsVisible(true);
    } else {
      const query = new URLSearchParams(location.search);
      const newFolder = query.get("folder");
      const newWidth = query.get("w");
      const newHeight = query.get("h");
      const newSortBy = query.get("sortBy")?.split("-");
      const newReleasesPerShelf = query.get("releasesPerShelf");
      if (
        newFolder &&
        newWidth &&
        newHeight &&
        newSortBy &&
        newSortBy?.length !== 0 &&
        newReleasesPerShelf
      ) {
        setSelectedFolderId(newFolder);
        setWidth(parseInt(newWidth));
        setHeight(parseInt(newHeight));
        setReleasesPerShelf(parseInt(newReleasesPerShelf));
        setSortBy(newSortBy);
        setFormIsVisible(false);
      }
    }
  }, [location]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    history.push(
      `/?w=${formState.width}&h=${
        formState.height
      }&sortBy=${formState.sortBy.join("-")}&releasesPerShelf=${
        formState.releasesPerShelf
      }&folder=${formState.selectedFolderId}`
    );
  };

  const handleBackToForm = () => {
    history.push("/");
    setFocusedRelease(null);
    setFormIsVisible(true);
  };

  const handlePrevRelease = () => {
    if (focusedRelease) {
      const currentIndex = collection.findIndex(
        (el) => el.instance_id === focusedRelease.instance_id
      );
      if (currentIndex === 0) return;
      handleHighlighting(collection[currentIndex - 1]);
      setFocusedRelease(collection[currentIndex - 1]);
    }
  };

  const handleNextRelease = () => {
    if (focusedRelease) {
      const currentIndex = collection.findIndex(
        (el) => el.instance_id === focusedRelease.instance_id
      );
      if (currentIndex === collection.length - 1) return;
      handleHighlighting(collection[currentIndex + 1]);
      setFocusedRelease(collection[currentIndex + 1]);
    }
  };

  const handleCloseRelease = () => {
    setFocusedRelease(null);
    handleHighlighting(null);
  };

  return (
    <section id="dashboard-container">
      {loading && <Loader />}
      <div id="sidebar" className={formIsVisible ? "form-is-visible" : ""}>
        {formIsVisible ? (
          <ShelfSetupForm
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            addDimension={handleAddDimension}
            removeDimension={handleRemoveDimension}
            width={width}
            height={height}
            sortBy={formState.sortBy}
            releasesPerShelf={releasesPerShelf}
            folders={folders}
            selectedFolderId={formState.selectedFolderId}
          />
        ) : (
          <>
            <SearchBar
              value={searchQuery}
              onChange={setSearchQuery}
              searchResults={searchResults}
              collection={collection}
              focusedRelease={handleFocusedRelease}
            />
            <h2>
              There are <strong>{collection.length} records</strong> in this
              shelving unit.
              <br />
              They are ordered as follows: <strong>{sortBy.join(" > ")}</strong>
            </h2>
            <ReleaseCard
              release={focusedRelease}
              prevRelease={handlePrevRelease}
              nextRelease={handleNextRelease}
              closeRelease={handleCloseRelease}
              folders={folders}
            />
            <BackToFormButton handleBackToForm={handleBackToForm} />
          </>
        )}
      </div>
      <div id="output">
        {!_.isEmpty(collection) && !loading && (
          <ShelvingUnit
            width={width}
            height={height}
            releasesPerShelf={releasesPerShelf}
            collection={collection}
            focusedRelease={handleFocusedRelease}
          />
        )}
      </div>
    </section>
  );
};

export default Dashboard;
