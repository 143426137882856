import axios from 'axios';

export const syncUser = async (): Promise<{ status: number; data: any }> => {
  const token = localStorage.getItem('auth-token');
  try {
    const { data } = await axios.get(process.env.REACT_APP_API_URL + '/syncuser', {
      headers: { 'auth-token': token },
    });
    return { status: 200, data };
  } catch (error) {
    if (error.response) {
      return { status: error.response.status, data: 'There was a problem syncing with Discogs. Please try again.' };
    } else if (error.request) {
      console.log(error.request);
      return { status: 400, data: 'An unknown error has occured' };
    } else {
      console.log('Error', error.message);
      return { status: 400, data: 'An unknown error has occured' };
    }
  }
};

export const getFolderReleases = async (folderId: string) => {
  const token = localStorage.getItem('auth-token');
  const { data } = await axios.get(process.env.REACT_APP_API_URL + '/getreleases/' + folderId, {
    headers: { 'auth-token': token },
  });

  return data;
};

export const getFolders = async () => {
  const token = localStorage.getItem('auth-token');
  const { data } = await axios.get(process.env.REACT_APP_API_URL + '/getfolders', {
    headers: { 'auth-token': token },
  });
  return data;
};
