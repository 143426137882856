import { VscInfo } from "react-icons/vsc";
import Tooltip from "../../Tooltip/Tooltip";

export interface ReleasesPerShelfProps {
  releasesPerShelf: number;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}

const ReleasesPerShelf: React.FC<ReleasesPerShelfProps> = ({
  releasesPerShelf,
  handleChange,
}) => {
  return (
    <fieldset>
      <label>
        <span>Records per shelf:</span>
        <input
          type="number"
          min="0"
          max="10000"
          name="releasesPerShelf"
          defaultValue={releasesPerShelf}
          onChange={handleChange}
        />
        <Tooltip text="This is down to preferences, but as a general idea: a square with 40 records is pretty loose, 60 starts to be packed.">
          <VscInfo></VscInfo>
        </Tooltip>
      </label>
    </fieldset>
  );
};

export default ReleasesPerShelf;
