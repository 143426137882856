import SelectDimensionField from "../SelectDimensionField/SelectDimensionField";
import { VscAdd, VscInfo, VscRemove } from "react-icons/vsc";
import "./SortByField.scss";
import Tooltip from "../../Tooltip/Tooltip";

export interface SortByFieldProps {
  sortBy: string[];
  selectedFolderId: string;
  addDimension: () => void;
  removeDimension: () => void;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}

const SortByField: React.FC<SortByFieldProps> = ({
  sortBy,
  addDimension,
  removeDimension,
  handleChange,
  selectedFolderId,
}) => {
  return (
    <fieldset className="sort-by">
      <label>
        <span>Sort by:</span>
        {sortBy.map((val, i) => (
          <SelectDimensionField
            key={i}
            isNotLast={i < sortBy.length - 1}
            val={val}
            handleChange={handleChange}
            selectedFolderId={selectedFolderId}
          />
        ))}
        <span className="dimensions-control">
          {sortBy.length < 6 && (
            <button
              type="button"
              onClick={addDimension}
              className="btn icon-btn"
            >
              <VscAdd />
            </button>
          )}
          {sortBy.length > 1 && (
            <button
              type="button"
              onClick={removeDimension}
              className="btn icon-btn"
            >
              <VscRemove />
            </button>
          )}
        </span>
        <Tooltip text="In order of priority (ie: Sort by label, then within label by artist, within artist by year. Chronological and Alphabetical.">
          <VscInfo></VscInfo>
        </Tooltip>
      </label>
    </fieldset>
  );
};

export default SortByField;
