import axios from "axios";

export const authorize = async (): Promise<any> => {
  const token = localStorage.getItem("auth-token");
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + "/authorize",
      {
        headers: { "auth-token": token },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getDCUser = async (): Promise<any> => {
  const token = localStorage.getItem("auth-token");
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + "/DCUser",
      {
        headers: { "auth-token": token },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getUser = async (): Promise<any> => {
  const token = localStorage.getItem("auth-token");
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + "/profile",
      {
        headers: { "auth-token": token },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const login = async (
  email: string,
  password: string
): Promise<{ status: number; data: any }> => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + "/login",
      { email, password }
    );
    return { status: 200, data };
  } catch (error) {
    if (error.response) {
      return { status: error.response.status, data: error.response.data };
    } else if (error.request) {
      console.log(error.request);
      return { status: 400, data: "An unknown error has occured" };
    } else {
      console.log("Error", error.message);
      return { status: 400, data: "An unknown error has occured" };
    }
  }
};

export const verifyEmail = async (id: string, token: string): Promise<any> => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + "/verify-account",
      { id, token }
    );
    return { status: 200, data };
  } catch (error) {
    if (error.response) {
      return { status: error.response.status, data: error.response.data };
    } else if (error.request) {
      console.log(error.request);
      return { status: 400, data: "An unknown error has occured" };
    } else {
      console.log("Error", error.message);
      return { status: 400, data: "An unknown error has occured" };
    }
  }
};

export const resendActivationLink = async (email: string): Promise<any> => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + "/resend-activation-link",
      { email }
    );
    return { status: 200, data };
  } catch (error) {
    if (error.response) {
      return { status: error.response.status, data: error.response.data };
    } else if (error.request) {
      console.log(error.request);
      return { status: 400, data: "An unknown error has occured" };
    } else {
      console.log("Error", error.message);
      return { status: 400, data: "An unknown error has occured" };
    }
  }
};

export const logout = async (): Promise<void> => {
  const token = localStorage.getItem("auth-token");
  try {
    await axios.post(process.env.REACT_APP_API_URL + "/logout", null, {
      headers: { "auth-token": token },
    });
    localStorage.removeItem("auth-token");
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const register = async (
  email: string,
  password: string
): Promise<{ status: number; data: string }> => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + "/register",
      { email, password }
    );
    return { status: 200, data };
  } catch (error) {
    if (error.response) {
      return { status: error.response.status, data: error.response.data };
    } else if (error.request) {
      console.log(error.request);
      return { status: 400, data: "An unknown error has occured" };
    } else {
      console.log("Error", error.message);
      return { status: 400, data: "An unknown error has occured" };
    }
  }
};
