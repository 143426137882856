import { useState, useEffect } from "react";
import "react-bulma-components/dist/react-bulma-components.min.css";
import "./App.scss";
import NavBar from "./components/NavBar/NavBar";
import Dashboard from "./containers/Dashboard/Dashboard";
import Login from "./containers/Login/Login";
import Register from "./containers/Register/Register";
import Profile from "./containers/Profile/Profile";
import AuthorizeDC from "./containers/AuthorizeDC/AuthorizeDC";
import { getUser, logout } from "./services/authService";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { UserInterface } from "./interfaces/user";
import FAQ from "./containers/FAQ/FAQ";
import ActivationLinkSent from "./containers/ActivationLinkSent/ActivationLinkSent";
import VerifyEmail from "./containers/VerifyEmail/VerifyEmail";
import ResendActivationLink from "./containers/ResendActivationLink/ResendActivationLink";

const token = localStorage.getItem("auth-token");

const App = () => {
  const emptyUser: UserInterface = {
    email: "",
    username: "",
    lastSyncedOn: null,
  };
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const [user, setUser] = useState<UserInterface>(emptyUser);
  const [isDCAuthorized, setIsDCAuthorized] = useState(!!user.username);

  const handleAuthenticate = async () => {
    const { email, username, lastSyncedOn } = await getUser();
    if (username) {
      setUser({ email, username, lastSyncedOn });
    } else {
      setUser({ email, username: "", lastSyncedOn: null });
    }
    setIsAuthenticated(true);
  };

  useEffect(() => {
    setIsDCAuthorized(!!user.username);
  }, [user]);

  useEffect(() => {
    (async () => {
      if (token) {
        const { email, username, lastSyncedOn } = await getUser();
        username
          ? setUser({ email, username, lastSyncedOn })
          : setUser({ email, username: "", lastSyncedOn: null });
        setIsAuthenticated(true);
      }
    })();
  }, []);

  const handleLogout = async (): Promise<void> => {
    setIsAuthenticated(false);
    await logout();
    setUser(emptyUser);
    window.location.href = "/";
  };

  const userHasBeenUpdated = (updatedUser: UserInterface) => {
    setUser(updatedUser);
  };

  // const betaUserToken = localStorage.getItem("beta-user-token");
  // const [isBetaUser, setIsBetaUser] = useState(!!betaUserToken);
  // const [betaUserPwd, setBetaUserPwd] = useState("");

  // const handleBetaUserSubmit = (e: React.SyntheticEvent) => {
  //   e.preventDefault();
  //   if (btoa(betaUserPwd) === "bGV0c3NvcnR0aGlzbWVzcw==") {
  //     localStorage.setItem("beta-user-token", btoa(betaUserPwd));
  //     setIsBetaUser(true);
  //   }
  // };

  return (
    <div className="App">
      <div id="phone-mask">
        <h1>
          K4ll4x Organizer is not yet optimized for mobile devices. <br /> To
          get the full experience, please try it out on a larger screen for now.
        </h1>
      </div>
      <Router>
        <NavBar user={user} onLogout={handleLogout} />
        <Switch>
          <Route path="/profile">
            <Profile user={user} userHasBeenUpdated={userHasBeenUpdated} />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/please-verify-your-account/:email">
            <ActivationLinkSent />
          </Route>
          <Route path="/verify-account/:id/:token">
            <VerifyEmail />
          </Route>
          <Route path="/resend-activation-link">
            <ResendActivationLink />
          </Route>
          <Route path="/faq">
            <FAQ />
          </Route>
          <Route path="/">
            {isAuthenticated ? (
              isDCAuthorized ? (
                <Dashboard />
              ) : (
                <AuthorizeDC userHasBeenUpdated={userHasBeenUpdated} />
              )
            ) : (
              <Login onAuthenticate={handleAuthenticate} />
            )}
          </Route>
        </Switch>
      </Router>
    </div>
  );

  // <form onSubmit={handleBetaUserSubmit} id='beta-user-form'>
  //   <p>
  //     Hi there, thanks for testing K4ll4x Organizer! <br /> Please enter the beta user password:
  //   </p>
  //   <input type='password' name='beta-user-pwd' onChange={(e) => setBetaUserPwd(e.target.value)} />
  //   <button className='btn'>Access</button>
  // </form>
};

export default App;
