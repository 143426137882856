import { useState } from 'react';
import { CgDisc } from 'react-icons/cg';
import { UserInterface } from '../../interfaces/user';
import { authorize } from '../../services/authService';
import { syncUser } from '../../services/collectionService';

export interface AuthorizeDCProps {
  userHasBeenUpdated: (user: UserInterface) => void;
}

const AuthorizeDC: React.FC<AuthorizeDCProps> = ({ userHasBeenUpdated }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<{ message: string }[]>([]);

  const onFocus = async () => {
    const response = await syncUser();
    if (response.status === 200) {
      const user = response.data;
      userHasBeenUpdated(user);
      setIsLoading(false);
    } else {
      setErrors([{ message: 'An error seems to have happen. Please try to authorize again.' }]);
      setIsLoading(false);
    }

    window.removeEventListener('focus', onFocus);
  };

  const handleConnectToDC = async (): Promise<void> => {
    window.addEventListener('focus', onFocus);
    setIsLoading(true);
    const url = await authorize();
    window.open(url);
  };

  return (
    <section className='columns mt-6 profile has-text-centered'>
      <div className='column is-6 is-offset-3'>
        <p>You need to authorize Discogs to use K4ll4x Organizer.</p>
        <p className='mb-2'>Make sure you are currently connected to Discogs in this browser, then click the button below.</p>

        {isLoading ? (
          <CgDisc className='spins loader m0auto fz-2 mb-1' />
        ) : (
          <button className='btn mb-2' onClick={handleConnectToDC}>
            Connect to Discogs
          </button>
        )}
        {errors.length > 0 &&
          errors.map((err) => (
            <p className='is-error' key={Math.floor(Math.random() * (10000 - 0 + 1)) + 0}>
              {err.message}
            </p>
          ))}
        <p>
          <i>
            This will ask for your permission to use your Discogs account on the app <br /> (ie: your username, your collection, your folders).
          </i>
        </p>
        <p>
          <i>Based on your collection size, this can take a while, be patient :)</i>
        </p>
      </div>
    </section>
  );
};

export default AuthorizeDC;
