import { useEffect, useState } from 'react';
import { ReleaseInterface } from '../../interfaces/collection';
import Shelf from '../ShelvingUnitComp/Shelf/Shelf';
import './ShelvingUnit.scss';

// Still needs to handle : overflow, sizing of the last shelve's releases (which should be the same size as the other ones)

export interface ShelvingUnitProps {
  width: number;
  height: number;
  releasesPerShelf: number;
  collection: ReleaseInterface[];
  focusedRelease: (release: ReleaseInterface) => void;
}

const ShelvingUnit: React.FC<ShelvingUnitProps> = ({ width, height, releasesPerShelf, collection, focusedRelease }) => {
  const [releaseWidth, setReleaseWidth] = useState(0);

  useEffect(() => {
    const setLastShelfReleasesWidth = () => {
      if (collection.length <= releasesPerShelf) {
        const lastShelfReleases: NodeListOf<HTMLElement> = document.querySelectorAll('.last-populated-shelf .release');
        for (let i = 0; i < lastShelfReleases.length; i++) {
          lastShelfReleases[i].style.flexGrow = '1';
        }
      } else {
        const firstRelease = document.getElementsByClassName('release')[0];
        const newReleaseWidth: string = window.getComputedStyle(firstRelease, null).getPropertyValue('width');
        setReleaseWidth(parseInt(newReleaseWidth));
      }
    };

    setLastShelfReleasesWidth();
  }, [releasesPerShelf, collection]);

  const getCurrentSlice = (rowNr: number, colNr: number): ReleaseInterface[] => {
    const startingPosition = (rowNr * width + colNr) * releasesPerShelf;
    const currentSlice = collection.slice(startingPosition, startingPosition + releasesPerShelf);
    return currentSlice;
  };

  const populateShelf = (rowNr: number, colNr: number): ReleaseInterface[] => {
    return getCurrentSlice(rowNr, colNr);
  };

  const isLastPopulatedShelf = (rowNr: number, colNr: number): boolean => {
    return getCurrentSlice(rowNr, colNr).length > 0 && getCurrentSlice(rowNr, colNr).length < releasesPerShelf;
  };

  const buildShelvingUnit = () => {
    const rows = [];
    for (let i = 0; i < height; i++) {
      const row = [];
      for (let j = 0; j < width; j++) {
        row.push(
          <Shelf
            releases={populateShelf(i, j)}
            key={j}
            isLastPopulatedShelf={isLastPopulatedShelf(i, j)}
            focusedRelease={focusedRelease}
            releaseWidth={releaseWidth}
          ></Shelf>
        );
      }
      rows.push(<tr key={i}>{row}</tr>);
    }
    return rows;
  };
  return (
    <table className='shelving-unit'>
      <tbody>{buildShelvingUnit()}</tbody>
    </table>
  );
};

export default ShelvingUnit;
