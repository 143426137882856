import { useState } from "react";
import Joi from "joi";
import { resendActivationLink } from "../../services/authService";
import { useHistory } from "react-router";

export interface ResendActivationLinkProps {}

const ResendActivationLink: React.FC<ResendActivationLinkProps> = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState<
    { message: string; target: React.ReactText | null }[]
  >([]);

  const history = useHistory();

  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .min(6)
      .max(255)
      .required()
      .label("Email"),
  });

  const validate = () => {
    const { error } = schema.validate({ email }, { abortEarly: false });
    if (!error) return null;
    const newErrors = error.details.map((x) => {
      return { message: x.message, target: x.path[0] };
    });
    return newErrors;
  };

  const handleSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    const newErrors = validate();
    if (newErrors) {
      setErrors(newErrors);
    } else {
      const response = await resendActivationLink(email);

      if (response.status === 200) {
        history.push("/");
      } else if (response.data) {
        setErrors([{ message: response.data, target: null }]);
      } else {
        setErrors([
          {
            message:
              "An unknown error has occured, I'm as disapointed as you are.",
            target: null,
          },
        ]);
      }
    }
  };
  return (
    <form onSubmit={handleSubmit} className="login-form">
      <label htmlFor="email">Email</label>
      <input
        type="text"
        name="email"
        onChange={(e) => setEmail(e.target.value)}
      />
      {errors.find((err) => err.target === "email") && (
        <p className="is-error">
          {errors.find((err) => err.target === "email")?.message}
        </p>
      )}
      <button className="btn">Resend activation link</button>
    </form>
  );
};

export default ResendActivationLink;
