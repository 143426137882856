import { useEffect, useState } from 'react';
import { UserInterface } from '../../interfaces/user';
import { authorize } from '../../services/authService';
import { syncUser } from '../../services/collectionService';
import moment from 'moment';
import './Profile.scss';
import { CgDisc } from 'react-icons/cg';

export interface ProfileProps {
  user: UserInterface;
  userHasBeenUpdated: (user: UserInterface) => void;
}

const Profile: React.FC<ProfileProps> = ({ user, userHasBeenUpdated }) => {
  const [isAuthorized, setIsAuthorized] = useState(!!user.username);
  const [lastSyncedOn, setLastSyncedOn] = useState(user.lastSyncedOn);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<{ message: string }[]>([]);

  useEffect(() => {
    setIsAuthorized(!!user.username);
    setLastSyncedOn(user.lastSyncedOn);
  }, [user]);

  const onFocus = async () => {
    const response = await syncUser();
    if (response.status === 200) {
      const user = response.data;
      userHasBeenUpdated(user);
      setIsAuthorized(true);
      setIsLoading(false);
    } else {
      setErrors([{ message: 'An error seems to have happen. Please try to authorize again.' }]);
      setIsLoading(false);
    }

    window.removeEventListener('focus', onFocus);
  };

  const handleConnectToDC = async (): Promise<void> => {
    window.addEventListener('focus', onFocus);
    setIsLoading(true);
    const url = await authorize();
    window.open(url);
  };

  const handleSync = async (): Promise<void> => {
    setIsLoading(true);
    setErrors([]);
    const response = await syncUser();
    if (response.status === 200) {
      const user = response.data;
      userHasBeenUpdated(user);
      setIsLoading(false);
    } else {
      setErrors([{ message: 'There was a problem syncing with Discogs. Please try again.' }]);
      setIsLoading(false);
    }
  };

  return (
    <section className='columns mt-6 profile'>
      <div className='column is-6 is-offset-3'>
        {isAuthorized ? (
          <>
            <p>
              <strong>Discogs username:</strong> {user.username}
            </p>
            <p>
              <strong>Email:</strong> {user.email}
            </p>
            <p>
              <strong>Sorting preference:</strong> to be added
            </p>
            <div className='profile-sync'>
              {isLoading ? (
                <CgDisc className='spins loader m0auto fz-2 mb-1' />
              ) : (
                <button className='btn' onClick={handleSync}>
                  Sync Discogs data
                </button>
              )}

              <p>
                <i>Last synced on {moment(lastSyncedOn).format('MMMM Do YYYY, h:mm a')}</i>
              </p>
              {errors.length > 0 &&
                errors.map((err) => (
                  <p className='is-error' key={Math.floor(Math.random() * (10000 - 0 + 1)) + 0}>
                    {err.message}
                  </p>
                ))}
            </div>
          </>
        ) : (
          <>
            <p>
              <strong>Email:</strong> {user.email}
            </p>
            <div className='profile-sync'>
              <p>
                You're still not connected to Discogs!
                <br />
                Please do so by clicking the button below
              </p>
              {isLoading ? (
                <CgDisc className='spins loader m0auto fz-2 mb-1' />
              ) : (
                <button className='btn' onClick={handleConnectToDC}>
                  Connect to Discogs
                </button>
              )}
              {errors.length > 0 &&
                errors.map((err) => (
                  <p className='is-error' key={Math.floor(Math.random() * (10000 - 0 + 1)) + 0}>
                    {err.message}
                  </p>
                ))}
              <p>
                <i>This will ask for your permission to use your Discogs account on the app (ie: your username, your collection, your folders).</i>
              </p>
              <p>
                <i>Based on your collection size, this can take a while, be patient :)</i>
              </p>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Profile;
