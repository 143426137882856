import { Link } from "react-router-dom";
import { UserInterface } from "../../interfaces/user";
import "./NavBar.scss";

export interface NavBarProps {
  onLogout: () => void;
  user: UserInterface;
}

const NavBar: React.FC<NavBarProps> = ({ onLogout, user }) => {
  return (
    <nav className="main-nav">
      <Link to="/" className="brand">
        K4ll4x Organizer <small>(beta)</small>
      </Link>

      <ul>
        <li>
          <Link to="/">App</Link>
        </li>
        <li>
          <Link to="/faq">FAQ</Link>
        </li>
        {user.email && (
          <>
            <li>
              <Link to="/profile">
                {user.username ? user.username : user.email}
              </Link>
            </li>
            <li>
              <button className="btn" onClick={onLogout}>
                Logout
              </button>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default NavBar;
