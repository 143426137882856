import { useState } from "react";
import { useParams } from "react-router";
import { resendActivationLink } from "../../services/authService";

export interface ActivationLinkSentProps {}

type emailParams = {
  email: string;
};

const ActivationLinkSent: React.FC<ActivationLinkSentProps> = () => {
  const { email } = useParams<emailParams>();
  const [buttonText, setButtonText] = useState("Resend activation link");
  const handleResendActivationLink = async (email: string) => {
    if (buttonText === "Resend activation link") {
      const response = await resendActivationLink(email);

      if (response.status === 200) {
        return setButtonText(response.data);
      }
    }
  };

  return (
    <section className="columns mt-6 profile has-text-centered">
      <div className="column is-6 is-offset-3">
        <p>
          Please activate your account by clicking the link sent to your email
          address.
        </p>
        <button
          className="btn"
          onClick={() => handleResendActivationLink(email)}
        >
          {buttonText}
        </button>
      </div>
    </section>
  );
};

export default ActivationLinkSent;
