import { VscClose, VscInfo } from "react-icons/vsc";
import Tooltip from "../../Tooltip/Tooltip";
import "./ShelfSizeField";

export interface ShelfSizeFieldProps {
  width: number;
  height: number;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}

const ShelfSizeField: React.FC<ShelfSizeFieldProps> = ({
  width,
  height,
  handleChange,
}) => {
  return (
    <fieldset>
      <label>
        Shelving unit size:
        <div className="shelf-size-inputs">
          <input
            type="number"
            min="1"
            max="20"
            placeholder="Width"
            name="width"
            defaultValue={width}
            onChange={handleChange}
            className="ml-1"
          />

          <VscClose />

          <input
            type="number"
            min="1"
            max="20"
            placeholder="Height"
            name="height"
            defaultValue={height}
            onChange={handleChange}
          />
        </div>
        <Tooltip text='In "number of squares". ie: 4x2, 4x4, 2x2, 5x5, etc.'>
          <VscInfo></VscInfo>
        </Tooltip>
      </label>
    </fieldset>
  );
};

export default ShelfSizeField;
